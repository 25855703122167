






/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';

const rater = require('rater-js');

export default Vue.extend({
    name: 'StarsReview',

    props: ['rating'],

    mounted() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        const myRater = rater({
            element: document.querySelector('#rater'),
            starSize: 32,
            rateCallback: function rateCallback(rating: never, done: any) {
                myRater.setRating(rating);
                self.$emit('update:rating', rating);
                done();
            },
        });

        myRater.setRating(parseInt(this.$props.rating));
    },
});
