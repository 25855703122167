


























































































































































import Vue from 'vue';
import dayjs from 'dayjs';
import i18n from '@/services/i18n';
import format from '@/services/format';
import helpers from '@/services/helpers';
import ValidityTimer from '@/components/ValidityTimer.vue';
import Map from '@/components/Map.vue';
// import Tag from '@/components/Tag.vue';
import StepProgress from '@/components/StepProgress.vue';

export default Vue.extend({
    name: 'RecapForm',

    components: { Map, ValidityTimer, StepProgress },

    props: ['deadline', 'booking'],

    data() {
        // // eslint-disable-next-line vue/no-mutating-props
        // this.booking.status = 'CONFIRMED';
        return {
            language: i18n.language,
            // meetingPointPositionLink: '',
        };
    },

    computed: {
        steps() {
            return [
                { status: 'CONFIRMED', text: this.$t('SHARED.RECAP_FORM.STEPS.CONFIRMED') },
                { status: 'DRIVER_EN_ROUTE', text: this.$t('SHARED.RECAP_FORM.STEPS.DRIVER_EN_ROUTE') },
                { status: 'DRIVER_HAS_ARRIVED', text: this.$t('SHARED.RECAP_FORM.STEPS.DRIVER_HAS_ARRIVED') },
                { status: 'PASSENGER_ON_BOARD', text: this.$t('SHARED.RECAP_FORM.STEPS.PASSENGER_ON_BOARD') },
                { status: 'COMPLETED', text: this.$t('SHARED.RECAP_FORM.STEPS.COMPLETED') },
            ];
        },
        bookingRideProps() {
            return this.booking?.connectorRideProps?.[this.booking.status];
        },
        bookingStatusSteps() {
            return (this as any).steps;
        },
        position() {
            return (this as any).steps?.findIndex((step: any) => {
                return step.status === this.booking.status;
            });
        },
        meetingPoint() {
            const meetingPoint = this.booking.connectorRideProps?.[this.booking.status]?.meetingPoint;
            return {
                description: this.$t(`PAGE4.MEETING_POINT_DESCRIPTION.${meetingPoint?.type ?? 'NOT_SET'}`),
                link: helpers.mapPageUrl({
                    latitude: meetingPoint?.latitude,
                    longitude: meetingPoint?.longitude,
                }),
            };
        },
        dateETA() {
            const bookingRideProps = this.booking.connectorRideProps?.[this.booking.status];
            return dayjs(bookingRideProps?.ride.dateBooked).add(bookingRideProps?.vehicle?.eta ?? 0, 'seconds');
        },
    },

    async mounted() {
        // nop
        // this.meetingPointPositionLink = ;
    },

    watch: {},

    mixins: [{ methods: { ...format } }],

    methods: {},
});
