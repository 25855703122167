








































































































import Vue from 'vue';

export default Vue.extend({
    name: 'StepProgress',

    props: ['steps', 'position'],
    // items: [{ text: '', ... }]

    data() {
        return {
            //
        };
    },

    mounted() {
        // nop
        // throw if position > steps.length
    },
});
