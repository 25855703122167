























































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import dayjs from 'dayjs';
import format from '@/services/format';
import helpers from '@/services/helpers';
import log from '@/services/log';
import mytako, { BookingStatus, ViewBookingResponse, ViewBookingStatusProps } from '@/services/mytako';
import segment from '@/services/segment';
import Card from '@/components/Card.vue';
import StarsReview from '@/components/StarsReview.vue';
import RecapForm from '@/components/RecapForm.vue';
// import BookingHistory from '@/components/BookingHistory.vue';
import i18n from '@/services/i18n';

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export default Vue.extend({
    name: 'Bookings',

    metaInfo: {
        title: 'Bookings',
    },

    components: {
        StarsReview,
        Card,
        RecapForm,
        // BookingHistory
    },

    mixins: [{ methods: { ...format } }],

    data() {
        return {
            bookingUuid: '',
            booking: ({ invoice: {}, review: {}, connectorRideProps: [], status: undefined, bookingHistory: [] } as unknown) as ViewBookingResponse,
            bookingStatusProps: ({
                destinationLocation: {},
                driver: {},
                fleet: {},
                meetingPoint: {},
                passenger: {},
                pickupLocation: {},
                price: {},
                ride: {},
                serviceLevelAgreement: {},
                vehicle: {},
            } as unknown) as ViewBookingStatusProps,
            meetingPointDescription: '',
            meetingPointInstructionsText: '',
            meetingPointPositionLink: '',
            locations: {} as any,
            language: i18n.language,

            showDialogConditions: false,
            showDialogCancel: false,
            showDialogCancelConfirm: false,
            showDialogCancelError: false,
            cancelReason: 'OTHER_DISPATCH_REASON',
            userCancelReasonList: [
                'FOUND_BETTER_PRICE',
                'ETA_TOO_LONG',
                'NOT_NEEDED_ANYMORE',
                'CAN_NOT_FIND_VEHICLE',
                'DRIVER_IS_LATE',
                'DRIVER_DID_NOT_SHOW_UP',
                'NOT_CLEAR_MEETING_INSTRUCTIONS',
                'COULD_NOT_CONTACT_CARRIER',
                'ASKED_BY_DRIVER_TO_CANCEL',
                'OTHER_USER_REASON',
            ],

            showDialogReviewConfirm: false,
            showDialogReviewError: false,
            ratingNumber05: 0,
            ratingCommentText: '',

            pageRefreshCount: 0,
            // progressBarValue: 0,
            // timeToLeft: 0,
        };
    },

    computed: {
        bookingLoaded(): boolean {
            return this?.booking?.status != null;
        },
    },

    // created() {
    //     bus.$on('force-refresh-bookings-page', () => {
    //         this.refreshPage();
    //     });
    // },

    async mounted() {
        log.warn('***** PAGE-4');
        segment.page('bookings');
        document.addEventListener('focus', this.onEnterTab);
        document.addEventListener('blur', this.onLeaveTab);
        this.$vuetify.goTo(0);
        await this.refreshPage();
    },

    beforeDestroy() {
        document.removeEventListener('focus', this.onEnterTab);
        document.removeEventListener('blur', this.onLeaveTab);
    },

    methods: {
        onLeaveTab() {
            log.debug('[Bookings] onLeaveTab');
            this.pageRefreshCount = process.env.VUE_APP_BOOKING_OVERVIEW_PAGE_REFRESH_COUNT;
        },

        onEnterTab() {
            log.debug('[Bookings] onEnterTab');
            this.pageRefreshCount = 0;
            this.refreshPage();
        },

        async refreshPage() {
            log.debug('PAGE-4: refreshing...');
            this.bookingUuid = this.$route.params.bookingUuid;
            log.debug(`this.bookingUuid=`, this.bookingUuid);
            if (this.bookingUuid == null) {
                log.debug(`booking #${this.bookingUuid} undefined; redirect to home`);
                this.$router.replace('/');
            }

            try {
                this.booking = await mytako.api.bookings.view({ uuid: this.bookingUuid });
                this.bookingStatusProps = this.booking.connectorRideProps[this.booking.status];
            } catch (error) {
                log.debug(`booking #${this.bookingUuid} not found or erroneous; redirect to home.`);
                this.$router.replace('/');
                return;
            }

            this.booking.invoice = {
                status: '',
                url: '',
                total: { amount: 0, currency: '' },
                ...(this.booking.invoice ?? {}),
            };

            // this.booking.review = {
            //     rating: 0,
            //     comment: '',
            //     ...(this.booking.review ?? {}),
            // };
            // this.booking.review =

            this.booking.bookingHistory = this.booking.bookingHistory ?? [];

            // const currentDate = dayjs();
            // const userETADate = dayjs(this.bookingStatusProps.ride.dateScheduled);
            // this.timeToLeft = userETADate.diff(currentDate, 'minute');
            // if (this.timeToLeft < 30) {
            //     const divisibleValue = this.timeToLeft < 11 ? 1 + this.timeToLeft * 0.01 : this.timeToLeft * 0.1;
            //     this.progressBarValue = 100 / divisibleValue;
            // }

            this.ratingNumber05 = (this.booking?.review?.rating ?? 0) / 20;
            this.ratingCommentText = this.booking?.review?.comment ?? '';

            this.cancelReason =
                (this.bookingStatusProps?.ride?.cancelReason ?? '').length > 0
                    ? (this.bookingStatusProps.ride.cancelReason as string)
                    : 'OTHER_DISPATCH_REASON';

            this.meetingPointDescription =
                (this.bookingStatusProps?.meetingPoint?.type ?? '').length > 0 ? (this.bookingStatusProps.meetingPoint.type as string) : 'NOT_SET';
            this.meetingPointInstructionsText = this.bookingStatusProps?.meetingPoint?.instructions ?? '';
            this.meetingPointPositionLink = helpers.mapPageUrl({
                latitude: this.bookingStatusProps.meetingPoint?.latitude,
                longitude: this.bookingStatusProps.meetingPoint?.longitude,
            });

            // handle page refresh timing
            this.prepareNextPageRefresh();
        },

        prepareNextPageRefresh() {
            this.pageRefreshCount++;
            if (this.pageRefreshCount > process.env.VUE_APP_BOOKING_OVERVIEW_PAGE_REFRESH_COUNT) {
                return;
            }
            log.debug('[Bookings] pageRefreshCount: ', this.pageRefreshCount);
            const refreshTimeList = {
                CREATED: helpers.seconds(10),
                NO_DRIVERS_AVAILABLE: null,
                CONFIRMED: helpers.seconds(20),
                DRIVER_EN_ROUTE: helpers.minutes(1),
                DRIVER_HAS_ARRIVED: helpers.minutes(1),
                PASSENGER_ON_BOARD: helpers.minutes(10),
                COMPLETED: helpers.hours(3),
                FLEET_CANCELED: null,
                USER_CANCELED: null,
                FAILED: null,
            };
            const refreshTime: number | null = refreshTimeList[this.booking.status as BookingStatus];
            if (refreshTime) {
                setTimeout(this.refreshPage, refreshTime);
            }
        },

        onBookingHistoryItemSelect(bookingHistoryItem: any) {
            log.debug('onBookingItemSelect bookingHistoryItem=', bookingHistoryItem);
            // segment.track('result/quote/select', {
            //     price: { ...quote.price, amount: quote.price.amount / 100 },
            //     fleetName: quote.fleet.name,
            //     fleetRating: quote.fleet.rating,
            //     timerStatus: this.deadline ? dayjs.unix(this.deadline).diff(dayjs()) / 1000 : null,
            // });
            this.$router.push({ name: 'Bookings', params: { bookingUuid: bookingHistoryItem.uuid } });
            this.refreshPage();
        },

        async onCancelBooking() {
            try {
                this.showDialogCancel = false;
                await mytako.api.bookings.cancel({
                    uuid: this.bookingUuid,
                    reason: this.cancelReason,
                    explanation: 'none',
                });
                segment.track('bookings/cancelation-reason', {
                    uuid: this.bookingUuid,
                    reason: this.cancelReason,
                    explanation: 'none',
                });
                this.showDialogCancelConfirm = true;
            } catch {
                this.showDialogCancelError = true;
            }
            setTimeout(this.refreshPage, 2000);
        },

        async onSaveReview() {
            try {
                await mytako.api.bookings.review({
                    uuid: this.bookingUuid,
                    rating: this.ratingNumber05 * 20,
                    comment: this.ratingCommentText,
                });
                segment.track('bookings/review', {
                    uuid: this.bookingUuid,
                    rating: this.ratingNumber05 * 20,
                    comment: this.ratingCommentText,
                });
                this.showDialogReviewConfirm = true;
            } catch {
                this.showDialogReviewError = true;
            }
            setTimeout(this.refreshPage, 2000);
        },
    },
});
